import { render, staticRenderFns } from "./InputLemmaBox.vue?vue&type=template&id=7d520c29&scoped=true"
import script from "./InputLemmaBox.vue?vue&type=script&lang=js"
export * from "./InputLemmaBox.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d520c29",
  null
  
)

export default component.exports